import React, {useCallback, useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import "./style.css";
import image_background from "../../../static/img/image_background.png"
import { FiUpload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { RiStickyNoteAddLine } from "react-icons/ri";
import fft from 'fft.js';

export const Upload = () => {
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');
  const [successFiles, setSuccessFiles] = useState([]);
  const [failFiles, setFailFiles] = useState([]);
  const navigate = useNavigate();
  const userid = localStorage.getItem("userid");
  const code = localStorage.getItem("code");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState("");
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (code === '2') {
      alert("권한이 없습니다.");
      navigate("/dashboard/home");
    }
  }, [code, navigate]);

  const onDrop = useCallback((acceptedFiles) => {
    const updateFiles = acceptedFiles.map((file) => ({
      file,
      note:"",
    }))
    setFiles((prevFiles) =>
      [...prevFiles, ...updateFiles].sort((a, b) =>
        a.file.name.localeCompare(b.file.name)
      )
    );
    setSuccessFiles([]);
    setFailFiles([]);
  }, []);

  const handleUpload = async () => {
    if (files.length === 0) {
      setUploadStatus('업로드할 파일이 없습니다.');
      return;
    }

    setIsUploading(true);

    const total = files.length;
    const successFiles = [];
    const failFiles = [];
    const BATCH_SIZE = 3;
    setUploadProgress(0);
    
    const startTime = performance.now();

    for (let i = 0; i < total; i += BATCH_SIZE) {
      const batchFiles = files.slice(i, i + BATCH_SIZE);

      const batchPromises = batchFiles.map(async (fileObj, index) => {
        const { file, note } = fileObj; // ✅ 올바른 파일 참조 방식
  
        try {
          // ✅ 각 파일마다 새로운 FormData 생성해야 함
          const formData = new FormData();
          formData.append("file", file);
          formData.append("notes", note);
          formData.append("user_id", userid);
  
          // ✅ 오디오 변환
          const audioContext = new AudioContext();
          const arrayBuffer = await file.arrayBuffer();
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
          const spectrogramData = extraactSpectrogram(audioBuffer);
          const spectrogramBinary = new Float32Array(spectrogramData.spectrogramData.flat()).buffer;
  
          formData.append("spectrogram_data", new Blob([spectrogramBinary], { type: "application/octet-stream" }));
  
          const response = await axios.post('/api/upload', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
          });
  
          if (response.status === 200) {
            const successList = response.data.success_file || [];
            const failList = response.data.fail_file || [];

            successFiles.push(...successList);
            failFiles.push(...failList);

          } else {
            failFiles.push(file.name);
          }
        } catch (error) {
          failFiles.push(file.name);
        }
        setUploadProgress(Math.round(((i + index + 1) / total) * 100)); // ✅ 개별 파일 진행률 반영
      });
      await Promise.all(batchPromises);
    }

    // ✅ 업로드 종료 시간 기록 & 총 소요 시간 계산
    const endTime = performance.now();
    const totalSeconds = Math.floor((endTime - startTime) / 1000); // 총 초 단위 변환

    // ✅ 시간, 분, 초 변환
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0"); // 시
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, "0"); // 분
    const seconds = String(totalSeconds % 60).padStart(2, "0"); // 초

    const formattedTime = `${hours}:${minutes}:${seconds}`;

    setUploadProgress(100);
    setSuccessFiles(successFiles);
    setFailFiles(failFiles);
    setUploadStatus(`업로드 완료 (총 ${formattedTime} 소요)`);
    setFiles([]);
    setIsUploading(false);
  };

  const handleAddNote = (index, newNote) => {
    setFiles((prevFiles) =>
      prevFiles.map((file, idx) =>
        idx === index ? { ...file, note: newNote } : file
      )
    );
  };

  const openModal = (index, existingNote) => {
    setCurrentFileIndex(index);
    setCurrentNote(existingNote || ""); // 기존 메모가 있으면 표시
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentFileIndex(null);
    setCurrentNote("");
  };

  const saveNote = () => {
    if (currentFileIndex !== null) {
      handleAddNote(currentFileIndex, currentNote);
    }
    closeModal();
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // 음성 파일 AUDIO DATA 및 FFT 변환
  const extraactSpectrogram = (audioBuffer) => {
    const channelData = audioBuffer.getChannelData(0);
  
    const fftSize = 2048;
    const hopSize = fftSize / 2;
    const numFrames = Math.floor(channelData.length / hopSize) - 1;
    const fftInstance = new fft(fftSize);

    const spectrumData = new Array(fftSize / 2).fill(0);
    const spectrogram = [];

    for (let i = 0; i < numFrames; i++) {
      const frame = channelData.slice(i * hopSize, i * hopSize + fftSize);
      fftInstance.realTransform(spectrumData, frame);
      fftInstance.completeSpectrum(spectrumData);
  
      const magnitudes = spectrumData.slice(0, fftSize / 2).map((v) => Math.log(Math.abs(v) + 1));
      spectrogram.push(magnitudes);
    }

    // JSON 데이터 생성
    return {
      spectrogramData : spectrogram
    };
  };


  return (
    <>
      <div className="title-space">
        <div className="text-area">
          <h1>소리 업로드</h1>
        </div>
        <div className="image-area">
          <img src={image_background} alt="background decoration" className="side-image"/>
        </div>
      </div>
      <div className="upload-main">
        <div className="upload">
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <div className="drag-search">
              <FiUpload size={28}/>
              녹음 파일 선택
            </div>
            <p className="drag-message">또는</p>
            <p className="drag-message">녹음 파일을 여기로 끌어 놓으세요.</p>
          </div>
          <div className="left-align">
            {files.length > 0 && (
              <div className="file-list">
                <ul>
                  {files.map((fileObj, index) => (
                    <li key={index} className="file-li">
                      <span>{fileObj.file?.name || "파일 이름 없음"}</span>
                      <RiStickyNoteAddLine
                        size={18}
                        onClick={() => openModal(index, fileObj.note)} // 모달 열기
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {isModalOpen && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <h3>소리 파일에 대한 메모를 작성해주세요.(25자이내)</h3>
                  <textarea
                    className="text-area2"
                    value={currentNote}
                    onChange={(e) => {
                      if (e.target.value.length <= 25) {
                        setCurrentNote(e.target.value)
                      }
                    }}
                    rows="1"
                  ></textarea>
                  <div className="modal-buttons">
                    <button className="save-button" onClick={saveNote}>
                      저장
                    </button>
                    <button className="cancel-button" onClick={closeModal}>
                      취소
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="upload-progress-wrapper">
            <div style={{ position: "relative" }}>
              <progress value={uploadProgress} max="100"></progress>
              <span className="upload-progress-text">{uploadProgress}%</span>
            </div>
          </div>
          <button className="button-sytle" onClick={handleUpload}>
            녹음 파일 업로드
          </button>
          {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
          <div className="file-list-wrapper">
            {successFiles.length > 0 && (
              <div className="file-list-container success">
                <h3>✅ 업로드 성공 파일 목록</h3>
                <ul>
                  {successFiles.map((file, index) => (
                    <li key={index}>{file}</li>
                  ))}
                </ul>
              </div>
            )}

            {failFiles.length > 0 && (
              <div className="file-list-container fail">
                <h3>❌ 업로드 실패 파일 목록</h3>
                <ul>
                  {failFiles.map((file, index) => (
                    <li key={index}>{file}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        {isUploading && (
          <div className="upload-overlay"/>
        )}
      </div>
    </>
  );
};
