import React, { useState, useEffect, useCallback, useRef } from "react";
import { IoIosOptions, IoMdDownload } from "react-icons/io";
import "./style.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdOutlineDeleteForever } from "react-icons/md";
import { IoPersonAdd } from "react-icons/io5";

export const Setting = () => {
  const [showOptions, setShowOptions] = useState(false); // 옵션 표시 여부 관리
  const [selectedPeriod, setSelectedPeriod] = useState("전체");
  const [selectedTeam, setSelectedTeam] = useState("전체");
  const selectedPeriodRef = useRef(selectedPeriod);
  const selectedTeamRef = useRef(selectedTeam);
  const navigate = useNavigate();
  const code = localStorage.getItem("code");
  const periods = ["전체", "1일", "1주", "1개월", "1년"];
  const teams = ["전체", "고라니", "너구리", "다람쥐", "비둘기"];
  const is_person = ["전체", "필터링 실행 X", "필터링 실행", "필터링 결과 사람음성 탐지"];
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 관리
  const [currentPage2, setCurrentPage2] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [searchResults, setSearchResults] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("파일관리");
  const [selectedFiles, setSelectedFiles] = useState([]); // 파일 선택 체크박스 상태 관리
  const [totalPages, setTotalPages] = useState(1);
  const [totalPages2, setTotalPages2] = useState(1);
  const pageGroupSize = 10; // 한번에 보여줄 페이지 버튼 수
  const currentGroup = Math.floor((currentPage - 1) / pageGroupSize); // 현재 몇 번째 페이지 그룹인지
  const startPage = currentGroup * pageGroupSize + 1;
  const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);
  const currentGroup2 = Math.floor((currentPage2 - 1) / pageGroupSize); // 현재 몇 번째 페이지 그룹인지
  const startPage2 = currentGroup2 * pageGroupSize + 1;
  const endPage2 = Math.min(startPage2 + pageGroupSize - 1, totalPages2);

  // 회원 추가 모달
  const [showModal, setShowModal] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    userId: "",
    password: "",
    team: "고라니",
    userType: "0", 
  });
  const toggleModal = () => {
    if (showModal === true) {
      setNewUser({
        name: "",
        userId: "",
        password: "",
        team: "고라니",
        userType: "0",
      });
    }
    setShowModal(!showModal);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleAddUser = async () => {
    try {
      const response = await axios.post("/api/add", {
        name: newUser.name,
        userId: newUser.userId,
        password: newUser.password,
        team: newUser.team,
        userType: parseInt(newUser.userType), // Ensure userType is an integer
      });

      if (response.status === 200) {
        alert("회원이 성공적으로 추가되었습니다!");
        setNewUser({
          name: "",
          userId: "",
          password: "",
          team: "고라니",
          userType: "0",
        });
        setShowModal(false); // Close modal on success
        fetchData();
      } else {
        alert("회원 추가 실패: " + response.data.message);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "알 수 없는 오류가 발생했습니다.";
      alert(errorMessage);
    }
  };

  const handleDeleteUser = async (data) => {
    const confirmDelete = window.confirm(`'${data.user_id}'님을 삭제하시겠습니까?`)

    if (confirmDelete) {
      try {
        const response = await axios.post("/api/delete", {
          userId: data.user_id,
        });
        if (response.status === 200) {
          alert("해당 회원 정보가 삭제되었습니다.");
          fetchData();
        } else {
          alert("회원 추가 실패: " + response.data.message);
        }
      } catch (error) {
        console.error("회원 삭제 중 오류 발생:", error);
        alert("오류 발생! 다시 시도해주세요.");
      }
    }
  }
  // 관리 항목 설정
  const handleChange = (event) => {
    setSelectedOption(event.target.value); 
  };

  // 검색 옵션 설정
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  // 검색 옵션 - 기간 설정
  const handlePeriodSelect = (period) => {
    setSelectedPeriod(period);
  };

  // 검색 옵션 - 팀명 설정
  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
  };

  // 검색 옵션 초기화 버튼
  const resetOptions = () => {
    setSelectedPeriod("전체");
    setSelectedTeam("전체");
    selectedPeriodRef.current = '전체';
    selectedTeamRef.current = '전체';
    fetchData(); // 초기화 후 데이터를 다시 가져옴
  };

  // 설정된 옵션으로 검색
  const handleSearch = () => {
    fetchData(); // 검색 옵션에 따라 데이터를 가져옴
  };

  // 데이터 서버 요청 함수
  const fetchData = useCallback(async () => {
    try {
      if (selectedOption === "파일관리") {
        const response = await axios.post("/api/setting_files", {
          params: {
            period: selectedPeriodRef.current,
            team: selectedTeamRef.current,
            page: currentPage,
            page_size: resultsPerPage 
          },
        });

        if (response && response.status === 200) {
          const { results, total_count } = response.data;
          setSearchResults(results);
  
          const calculatedPages = Math.ceil(total_count / resultsPerPage);
          setTotalPages(calculatedPages);
        }

      } else {
        const response = await axios.post("/api/setting_users", {
          params: {
            page: currentPage2,
            page_size: resultsPerPage
          }
        });

        if (response && response.status === 200) {
          const { results, total_count } = response.data;
          setUserData(results);

          const calculatedPages = Math.ceil(total_count / resultsPerPage);
          setTotalPages2(calculatedPages);
        }
      }
    } catch (error) {
      console.error("데이터를 가져오는 중 오류 발생:", error);
    }
  },[resultsPerPage, currentPage, currentPage2, selectedOption]);

  // 처음 렌더링 시 권한 확인 Hook 함수
  useEffect(() => {
    if (code !== '3') {
      alert("권한이 없습니다.");
      navigate("/dashboard/home");
    }
    fetchData(); // 컴포넌트가 마운트될 때 fetchData 호출
  }, [fetchData, code, navigate]);

  // fetchData 함수 내 검색 옵션 최신 값 유지를 위한 Hook 함수
  useEffect(() => {
    selectedPeriodRef.current = selectedPeriod;
  }, [selectedPeriod]);
  
  // fetchData 함수 내 검색 옵션 최신 값 유지를 위한 Hook 함수
  useEffect(() => {
    selectedTeamRef.current = selectedTeam;
  }, [selectedTeam]);

  // 페이지 그룹 네비게이션 - 선택된 페이지 번호로 현재 페이지 업데이트
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 파일 다운로드
  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = ""; 
    document.body.appendChild(link); 
    link.click();
    document.body.removeChild(link);
  };

  // AI 필터링
  const handleFiltering = async (fileUrl) => {
    try {
      // 선택된 옵션을 쿼리 파라미터로 전달
      await axios.post("/api/filtering", {
        params: {
          url: fileUrl,
        },
      });
    } catch (error) {
      console.error("필터링 중 오류 발생:", error);
    }
  };

  // 체크박스를 클릭했을 때 실행되는 함수
  // 이미 선택된 파일인 경우: selectedFiles 배열에서 제거 (체크 해제)
  // 선택되지 않았던 파일인 경우: selectedFiles 배열에 추가 (체크)
  const handleCheckboxChange = (file) => {
    setSelectedFiles((prevSelected) => {
      const exists = prevSelected.some((f) => f.file_id === file.file_id); // 기존 배열에 있는지 확인 -> 있으면 true 없으면 false
      return exists // 조건 ? 참일 때 값 : 거짓일 때 값
        ? prevSelected.filter((f) => f.file_id !== file.file_id) // 체크 해제
        : [...prevSelected, { file_id: file.file_id, file_url: file.file_url }]; // 체크
    });
  };

  // 현재 페이지 항목 전체 체크/해제
  const handleSelectAll = (e) => {
    const currentPageFiles = searchResults.map((f) => ({
      file_id: f.file_id,
      file_url: f.file_url
    }));
  
    if (e.target.checked) {
      // 체크된 것 추가 (중복 방지)
      setSelectedFiles((prevSelected) => {
        const newFiles = currentPageFiles.filter(
          (f) => !prevSelected.some((s) => s.file_id === f.file_id)
        );
        return [...prevSelected, ...newFiles];
      });
    } else {
      // 해제
      setSelectedFiles((prevSelected) =>
        prevSelected.filter(
          (s) => !currentPageFiles.some((f) => f.file_id === s.file_id)
        )
      );
    }
  };

  // 모든 데이터가 체크되어 있는지? true false 반환
  const isAllSelected = searchResults.every((result) =>
    selectedFiles.some((f) => f.file_id === result.file_id)
  );

  // 선택 항목 AI 필터링 함수
  const handleBulkFiltering = async () => { // 선택 항목 필터링 실행
    if (selectedFiles.length === 0) {
      alert("선택된 항목이 없습니다.");
      return;
    }
    setLoading(true);
    try {
      for (const file of selectedFiles) {
        await handleFiltering(file.file_url); // 이미 file_url 포함
      }
      setSelectedFiles([]);
      fetchData();
    } catch (error) {
      console.error("필터링 중 오류 발생:", error);
    } finally {
      setLoading(false);
    }
  };

  // 선택 항목 파일 삭제 함수
  const handleDeleteFile = async () => {
    const confirmDeleteFile = window.confirm(`파일을 삭제하시겠습니까?`);
    if (confirmDeleteFile) {
      if (selectedFiles.length === 0) {
        alert("선택된 항목이 없습니다.");
        return;
      }
      setLoading(true);
      try {
        const response = await axios.post("/api/file_delete", {
          file_id: selectedFiles.map(file => file.file_id), 
        });
        if (response.status === 200) {
          fetchData(); // 데이터 다시 불러오기
        }
      } catch (error) {
        console.error("파일 삭제 중 오류 발생:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // 선택 항목 파일 다운로드 함수
  const handleDownloadFile = async () => { // 선택 항목 필터링 실행
    const confirmDeleteFile = window.confirm(`파일을 다운로드하시겠습니까?`)
    if (confirmDeleteFile) {
      if (selectedFiles.length === 0) {
        alert("선택된 항목이 없습니다.");
        return;
      }
      setLoading(true);
      try {
        const response = await axios.post(
          "/api/download_zip",
          { file_urls: selectedFiles.map((f) => f.file_url) },
          { responseType: "blob" }
        );
        
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = URL.createObjectURL(blob);
        
        const a = document.createElement("a");
        a.href = url;
        a.download = "selected_files.zip";
        a.click();
        URL.revokeObjectURL(url);

        setSelectedFiles([]);
      } catch (error) {
        console.error("필터링 중 오류 발생:", error);
      } finally {
        setLoading(false); // 모든 작업 완료 후 비활성화 해제
      }
    }
  };

  const handlePageChange2 = (pageNumber2) => {
    setCurrentPage2(pageNumber2);
  };

  return (
    <div className="download">
      <div className="search-options" style={{justifyContent:'start'}}>
        <p className="option-title" style={{marginRight:'40px', whiteSpace:'nowrap'}}>관리 항목</p>
        <label style={{marginLeft:'20px'}}>
          <input 
            type='radio' 
            value="파일관리" 
            checked={selectedOption === "파일관리"} 
            onChange={handleChange}
          />
          파일관리
        </label>
        <label style={{marginLeft:'20px'}}>
          <input 
            type='radio' 
            value="회원관리" 
            checked={selectedOption === "회원관리"} 
            onChange={handleChange}
          />
          회원관리
        </label>
      </div>
      {selectedOption === '파일관리' ? (
        <>
          <div className="search-options">
            <p className="option-title">검색 옵션 설정</p>
            <button className="toggle-button" onClick={toggleOptions}>
              <IoIosOptions size={20} style={{ marginRight: "8px" }} />
              옵션
            </button>
          </div>
          {showOptions && (
            <div className="options-container">
              <div className="option-group">
                <h3>기간</h3>
                <div className="option-buttons">
                  {periods.map((period) => (
                    <button
                      key={period}
                      className={`option-button ${
                        selectedPeriod === period ? "selected" : ""
                      }`}
                      onClick={() => handlePeriodSelect(period)}
                    >
                      {period}
                    </button>
                  ))}
                </div>
              </div>
              {code === '3' && (
                <div className="option-group">
                <h3>팀명</h3>
                <div className="option-buttons">
                  {teams.map((team) => (
                    <button
                      key={team}
                      className={`option-button ${
                        selectedTeam === team ? "selected" : ""
                      }`}
                      onClick={() => handleTeamSelect(team)}
                    >
                      {team}
                    </button>
                  ))}
                </div>
              </div>
              )} 
              <div className="options-footer">
                <button className="search-button" onClick={handleSearch}>
                  검색
                </button>
                <button className="reset-button" onClick={resetOptions}>
                  초기화
                </button>
              </div>
            </div>
          )}
          <div className="search-result">
            <div className="checkbutton">
              <div className="left-group">
                <label>
                  페이지당 보기:
                  <select
                    value={resultsPerPage} 
                    onChange={(e) => {
                      setResultsPerPage(Number(e.target.value));
                      setCurrentPage(1);
                    }}
                    style={{ marginLeft: '10px', padding: '5px' }}
                  >
                    <option value={10}>10개씩</option>
                    <option value={20}>20개씩</option>
                    <option value={40}>40개씩</option>
                    <option value={100}>100개씩</option>
                  </select>
                </label>
              </div>
              <div className="right-group">
                <button onClick={handleDownloadFile} disabled={loading}>
                  다운로드
                </button>
                <button onClick={handleBulkFiltering} disabled={loading}>
                  AI 필터링
                </button>
                <button onClick={handleDeleteFile} disabled={loading}>
                  파일 삭제
                </button>
              </div>
            </div>
            <table className="result-table">
              <thead>
                <tr>
                  <th>
                    <input 
                      type="checkbox"
                      onChange={handleSelectAll} // 사용자가 클릭했을 때 실행할 함수
                      checked={isAllSelected} // 상태에 따라 체크 상태를 반영
                    />
                  </th>
                  <th>No</th>
                  <th>파일명</th>
                  <th>날짜</th>
                  <th>팀명</th>
                  <th>업로더</th>
                  <th>사람음성발생여부</th>
                  <th>다운로드</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((result, index) => (
                  <tr key={result.file_id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedFiles.some((f) => f.file_id === result.file_id)}
                        onChange={() => handleCheckboxChange(result)} 
                      />
                    </td>
                    <td>{(currentPage - 1) * resultsPerPage + index + 1}</td>
                    <td>{result.filename}</td>
                    <td>{result.upload_date}</td>
                    <td>{result.team}</td>
                    <td>{result.uploader}</td>
                    <td>{is_person[result.is_person+1]}</td>
                    <td>
                      <button
                        className="download-button"
                        onClick={() => handleDownload(result.file_url)}
                      >
                        <IoMdDownload size={16} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {/* 이전 그룹으로 이동 */}
              {startPage > 1 && (
                <button
                  className="page-button"
                  onClick={() => handlePageChange(startPage - 1)}
                >
                  이전
                </button>
              )}

              {/* 현재 그룹의 페이지 버튼 */}
              {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
                <button
                  key={startPage + i}
                  className={`page-button ${
                    currentPage === startPage + i ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(startPage + i)}
                >
                  {startPage + i}
                </button>
              ))}

              {/* 다음 그룹으로 이동 */}
              {endPage < totalPages && (
                <button
                  className="page-button"
                  onClick={() => handlePageChange(endPage + 1)}
                >
                  다음
                </button>
              )}
            </div>
          </div>
        </>
      ) : 
        <>
          <div className="search-options">
            <p className="option-title">회원 관리 옵션</p>
            <button className="toggle-button" onClick={toggleModal}>
              <IoPersonAdd size={16} style={{ marginRight: "8px" }} />
              회원 추가
            </button>
          </div>
          {showModal && (
            <div className="modal-overlay2">
              <div className="modal2" onClick={(e) => e.stopPropagation()}>
                <h3>회원 추가</h3>
                <label>
                  이름
                  <input
                    type="text"
                    name="name"
                    value={newUser.name}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  아이디
                  <input
                    type="text"
                    name="userId"
                    value={newUser.userId}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  비밀번호
                  <input
                    type="password"
                    name="password"
                    value={newUser.password}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  팀명
                  <select name="team" value={newUser.team} onChange={handleInputChange}>
                    <option value="고라니">고라니</option>
                    <option value="너구리">너구리</option>
                    <option value="다람쥐">다람쥐</option>
                    <option value="비둘기">비둘기</option>
                  </select>
                </label>
                <label>
                  분류
                  <select name="userType" value={newUser.userType} onChange={handleInputChange}>
                    <option value="0">업로더 및 라벨러</option>
                    <option value="1">업로더</option>
                    <option value="2">라벨러</option>
                    <option value="3">관리자</option>
                  </select>
                </label>
                <div className="modal-footer">
                  <button onClick={handleAddUser}>추가</button>
                  <button onClick={toggleModal}>닫기</button>
                </div>
              </div>
            </div>
          )}
          <div className="search-result">
            <table className="result-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>이름</th>
                  <th>아이디</th>
                  <th>팀명</th>
                  <th>분류</th>
                  <th>소리 업로드 개수</th>
                  <th>소리 분석 개수</th>
                  <th>회원삭제</th>
                </tr>
              </thead>
              <tbody>
                {userData.map((result, index) => (
                  <tr key={result._id}>
                    <td>{(currentPage2 - 1) * resultsPerPage + index + 1}</td>
                    <td>{result.name}</td>
                    <td>{result.user_id}</td>
                    <td>{result.team}</td>
                    <td>{result.is_admin}</td>
                    <td>{result.count_upload}</td>
                    <td>{result.count_labeling}</td>
                    <td>
                      <button
                        className="download-button"
                        onClick={() => handleDeleteUser(result)}
                      >
                        <MdOutlineDeleteForever size={20} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {/* 이전 그룹으로 이동 */}
              {startPage2 > 1 && (
                <button
                  className="page-button"
                  onClick={() => handlePageChange2(startPage2 - 1)}
                >
                  이전
                </button>
              )}

              {/* 현재 그룹의 페이지 버튼 */}
              {Array.from({ length: endPage2 - startPage2 + 1 }, (_, i) => (
                <button
                  key={startPage2 + i}
                  className={`page-button ${
                    currentPage2 === startPage2 + i ? "active" : ""
                  }`}
                  onClick={() => handlePageChange2(startPage2 + i)}
                >
                  {startPage2 + i}
                </button>
              ))}

              {/* 다음 그룹으로 이동 */}
              {endPage2 < totalPages2 && (
                <button
                  className="page-button"
                  onClick={() => handlePageChange2(endPage2 + 1)}
                >
                  다음
                </button>
              )}
            </div>
          </div>
        </>
      }
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner" />
          <p>작업을 수행하고 있습니다.</p>
        </div>
      )}
    </div>
  );
};