import React, {useEffect, useState} from "react";
import { Topmenu } from "../../components/Topmenu/Topmenu.jsx";
import { Routes, Route, useLocation } from "react-router-dom";
import { Home } from "./Home/index.js";
import { Download } from "./Download/index.js";
import { Upload } from "./Upload/index.js";
import { Labeling } from "./Labeling/index.js";
import { Setting } from "./Setting/index.js";
import ProtectedRoute from "../../components/ProtectedRoute/ProtectedRoute.jsx"
import "./dashboardLayout.css";
import Modal from "../../components/Modal/Modal.jsx"

export const DashboardLayout = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);

  const openModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalType(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const expiry = localStorage.getItem("expiry");
      if (expiry && Date.now() < Number(expiry)) {
        localStorage.setItem("expiry", Date.now() + 3600 * 1000);
      }
    }, 60000); // 1분마다 체크

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 클리어
  }, []);

  const footerBackgroundColor = () => {
    switch (location.pathname) {
      case "/dashboard/home":
        return "#2d5d4d"; // Home 페이지 배경색
      case "/dashboard/download":
        return "#f9f9f9"; // Download 페이지 배경색
      case "/dashboard/upload":
        return "#ECECEB"; // Upload 페이지 배경색
      case "/dashboard/labeling":
        return "#ffffff"; // Labeling 페이지 배경색
      default:
        return "#f9f9f9"; // 기본 배경색
    }
  };

  const footerTextColor = () => {
    switch (location.pathname) {
      case "/dashboard/home":
        return "#ffffff"; // 흰색 글자
      case "/dashboard/download":
        return "#666666"; // 검정색 글자
      case "/dashboard/upload":
        return "#666666"; // 어두운 회색 글자
      case "/dashboard/labeling":
        return "#666666"; // 흰색 글자
      default:
        return "#666666"; // 기본 회색
    }
  };

  return (
    <div className="dashboard-layout">
      <Topmenu />
      <div className="main-content">
        <div className="main-body">
        <Routes>
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="download"
            element={
              <ProtectedRoute>
                <Download />
              </ProtectedRoute>
            }
          />
          <Route
            path="upload"
            element={
              <ProtectedRoute>
                <Upload />
              </ProtectedRoute>
            }
          />
          <Route
            path="labeling"
            element={
              <ProtectedRoute>
                <Labeling />
              </ProtectedRoute>
            }
          />
          <Route
            path="setting"
            element={
              <ProtectedRoute>
                <Setting />
              </ProtectedRoute>
            }
          />
        </Routes>
        </div>
      </div>
      <footer className="footer" style={{ backgroundColor : footerBackgroundColor() }}>
        <div style={{ color : footerTextColor() }}>소리탐험대: 소리로 찾는 우리 주변의 생물다양성</div>
        <div>
          <button style={{ backgroundColor : footerBackgroundColor(), color : footerTextColor()}} onClick={() => openModal("terms")} className="footer-link">
            이용약관
          </button>
          <button style={{ backgroundColor : footerBackgroundColor(), color : footerTextColor()}} onClick={() => openModal("privacy")} className="footer-link">
            개인정보처리방침
          </button>
        </div>
        <div style={{ color : footerTextColor() }}>Copyright © 2024 소리탐험대: 소리로 찾는 우리 주변의 생물다양성 All rights reserved.</div>
      </footer>
      <Modal type={modalType} isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};



