import React, { useState, useEffect, useRef } from "react";
import { IoIosMore } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import { IoIosArrowForward, IoIosMenu, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { CiMenuKebab } from "react-icons/ci";

export const Topmenu = () => {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isDropdown1Open, setDropdown1Open] = useState(false);
  const [isDropdown2Open, setDropdown2Open] = useState(true);
  const [isDropdown3Open, setDropdown3Open] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const userid = localStorage.getItem("userid");
  const name = localStorage.getItem("name");
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const code = localStorage.getItem("code");

  const isActive = (path) => location.pathname === path;

  // 창 크기를 감지하는 useEffect
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen); // 사이드바 상태 토글
    setDropdown2Open(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logout = () => {
    localStorage.clear()
    window.location.href = "/";
  }

  return (
    <div className="topmenu">
      <div className="top-header">
        <div className="top-title">
          <Link to="https://soriexplorer.com/home">
            소리탐험대
          </Link>
        </div>
      </div>
      <div className="menu">
        {windowWidth > 990 && (
          <>
            <Link to="https://soriexplorer.com/home" className="menu-item">
              Home
            </Link>
            <Link to="https://soriexplorer.com/about" className="menu-item">
              소개
            </Link>
            <div className="menu-item">
              <Link to="https://soriexplorer.com/21" className="menu-item">
                참여 방법
              </Link>
              <div className="submenu">
                <Link to="https://soriexplorer.com/21" className="submenu-item">
                  소리 녹음
                </Link>
                <Link to="https://soriexplorer.com/20" className="submenu-item">
                  소리 분석
                </Link>
              </div>
            </div>
            <div className="menu-item">
              <Link to="/dashboard/home" className="menu-item">
                참여하기
              </Link>
              <div className="submenu">
                <Link to="/dashboard/home" className="submenu-item">
                  대시보드
                </Link>
                <Link to="/dashboard/upload" className="submenu-item">
                  소리 업로드하기
                </Link>
                <Link to="/dashboard/labeling" className="submenu-item">
                  소리 분석하기
                </Link>
                <Link to="/dashboard/download" className="submenu-item">
                  소리 다운로드하기
                </Link>
                {code === '3' && (
                  <Link to="/dashboard/setting" className="submenu-item">
                    관리자 설정
                  </Link>
                )}
                
              </div>
            </div>
          </>
        )}

        {/* 1180px 이상일 때 표시되는 추가 메뉴 */}
        {windowWidth > 1180 && (
          <div className="menu-item">
            <Link to="https://soriexplorer.com/31" className="menu-item">
              생물 소리 도감
            </Link>
            <div className="submenu">
              <Link to="https://soriexplorer.com/31" className="submenu-item">
                매미류
              </Link>
              <Link to="https://soriexplorer.com/26" className="submenu-item">
                조류
              </Link>
              <Link to="https://soriexplorer.com/28" className="submenu-item">
                무미양서류
              </Link>
              <Link to="https://soriexplorer.com/27" className="submenu-item">
                메뚜기목
              </Link>
              <Link to="https://soriexplorer.com/29" className="submenu-item">
                포유류
              </Link>
            </div>
          </div>
        )}

        {/* 1237px 이상일 때 모든 메뉴 표시 */}
        {windowWidth > 1237 && (
          <>
            <Link to="https://soriexplorer.com/notice" className="menu-item">
              소식
            </Link>
            <Link to="https://soriexplorer.com/qna" className="menu-item">
              게시판
            </Link>
          </>
        )}
        {windowWidth <= 1180 && windowWidth > 990 && (
          <div className="menu-item">
            <IoIosMore size={20}/>
            <div className="submenu submenu-drop">
              <div className="dropdown-item">
                <Link to="https://soriexplorer.com/31" className="submenu-item with-arrow">
                  생물 소리 도감
                  <IoIosArrowForward className="arrow-icon" />
                </Link>
                <div className="submenu-children">
                  <Link to="https://soriexplorer.com/31" className="submenu-item">
                    매미류
                  </Link>
                  <Link to="https://soriexplorer.com/26" className="submenu-item">
                    조류
                  </Link>
                  <Link to="https://soriexplorer.com/28" className="submenu-item">
                    무미양서류
                  </Link>
                  <Link to="https://soriexplorer.com/27" className="submenu-item">
                    메뚜기목
                  </Link>
                  <Link to="https://soriexplorer.com/29" className="submenu-item">
                    포유류
                  </Link>
                </div>
              </div>
              <Link to="https://soriexplorer.com/notice" className="submenu-item">
                소식
              </Link>
              <Link to="https://soriexplorer.com/qna" className="submenu-item">
                게시판
              </Link>
            </div>
          </div>
        )}

        {windowWidth <= 1237 && windowWidth > 1180 && (
          <div className="menu-item">
            <IoIosMore size={20}/>
            <div className="submenu">
              <Link to="https://soriexplorer.com/notice" className="submenu-item">
                소식
              </Link>
              <Link to="https://soriexplorer.com/qna" className="submenu-item">
                게시판
              </Link>
            </div>
          </div>
        )}
      </div>
      <div>
        {windowWidth >990 && (
          <div className="logout-container">
            <p>
              <span className="user-name">{name}</span>
              <span className="user-suffix"> 님</span>
            </p>
            <button className="logout-button" onClick={logout}>로그아웃</button>
          </div>
        )}
      </div>
      <div>
        {windowWidth <= 990 && (
          <IoIosMenu className="menu-icon" onClick={toggleSidebar} size={24} />
        )}
      </div>
      <div className={`sidebar-panel ${isSidebarOpen ? "open" : ""}`}>
        <div className="sidebar-header">
          {isLoggedIn ? ( // isLoggedIn이 true일 때
            <>
              <div className="sidebar-header-name">
                <p className="sidebar-title">{name}</p>
                <p className="sidebar-subtitle">{userid}</p>
              </div>
              <div ref={menuRef}>
                <CiMenuKebab
                  className="sidebar-menu"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsMenuOpen(!isMenuOpen);
                  }}
                />
                {isMenuOpen && (
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-item" onClick={logout}>
                      로그아웃
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : ( // isLoggedIn이 null일 때
            <p className="sidebar-title">로그인이 필요합니다.</p>
          )}
        </div>
        <div className="sidebar-content">
          <Link to="https://soriexplorer.com/home" className="sidebar-item">Home</Link>
          <div className="sidebar-line"></div>
          <Link to="https://soriexplorer.com/about" className="sidebar-item">소개</Link>
          <div className="sidebar-line"></div>
          <div className="sidebar-item" onClick={() => setDropdown1Open(!isDropdown1Open)}>
            참여방법 {isDropdown1Open ? <IoIosArrowUp/> : <IoIosArrowDown/>}
          </div>
          {isDropdown1Open && (
            <div className="sidebar-submenu">
              <Link to="https://soriexplorer.com/21" className="sidebar-item sidebar-item-dropdown">
                소리 녹음
              </Link>
              <Link to="https://soriexplorer.com/20" className="sidebar-item sidebar-item-dropdown2">
                소리 분석
              </Link>
            </div>
          )}
          <div className="sidebar-item" onClick={() => setDropdown2Open(!isDropdown2Open)}>
            참여하기 {isDropdown2Open ? <IoIosArrowUp/> : <IoIosArrowDown/>}
          </div>
          {isDropdown2Open && (
            <div className="sidebar-submenu">
              <Link to="/dashboard/home" className={`sidebar-item sidebar-item-dropdown ${isActive("/dashboard/home") ? "active" :""}`}>
                대시보드
              </Link>
              <Link to="/dashboard/upload" className={`sidebar-item sidebar-item-dropdown ${isActive("/dashboard/upload") ? "active" :""}`}>
                소리 업로드하기
              </Link>
              <Link to="/dashboard/labeling" className={`sidebar-item sidebar-item-dropdown ${isActive("/dashboard/labeling") ? "active" :""}`}>
                소리 분석하기
              </Link>
              <Link to="/dashboard/download" className={`sidebar-item sidebar-item-dropdown ${isActive("/dashboard/download") ? "active" :""}`}>
                소리 다운로드하기
              </Link>
              {code === '3' && (
                <Link to="/dashboard/setting" className={`sidebar-item sidebar-item-dropdown ${isActive("/dashboard/setting") ? "active" :""}`}>
                  관리자 설정
                </Link>
              )}
            </div>
          )}
          <div className="sidebar-line"></div>
          <div className="sidebar-item" onClick={() => setDropdown3Open(!isDropdown3Open)}>
            생물 소리 도감 {isDropdown3Open ? <IoIosArrowUp/> : <IoIosArrowDown/>}
          </div>
          {isDropdown3Open && (
            <div className="sidebar-submenu">
              <Link to="https://soriexplorer.com/31" className="sidebar-item sidebar-item-dropdown">
                매미류
              </Link>
              <Link to="https://soriexplorer.com/26" className="sidebar-item sidebar-item-dropdown">
                조류
              </Link>
              <Link to="https://soriexplorer.com/28" className="sidebar-item sidebar-item-dropdown">
                무미양서류
              </Link>
              <Link to="https://soriexplorer.com/27" className="sidebar-item sidebar-item-dropdown">
                메뚜기목
              </Link>
              <Link to="https://soriexplorer.com/29" className="sidebar-item sidebar-item-dropdown2">
                포유류
              </Link>
            </div>
          )}
          <Link to="https://soriexplorer.com/notice" className="sidebar-item">소식</Link>
          <div className="sidebar-line"></div>
          <Link to="https://soriexplorer.com/qna" className="sidebar-item">게시판</Link>
          <div className="sidebar-line"></div>
        </div>
      </div>
      {isSidebarOpen && (
        <div className="overlay" onClick={toggleSidebar}></div>
      )}

      {isSidebarOpen && (
        <IoCloseOutline className="close-icon-outside" onClick={toggleSidebar} />
      )}
    </div>
  );
};