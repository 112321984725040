import React, { useState, useEffect, useCallback, useRef } from "react";
import { IoIosOptions } from "react-icons/io";
import "./style.css";
import axios from "axios";
import { IoMdDownload } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export const Download = () => {
  const [showOptions, setShowOptions] = useState(false); // 옵션 표시 여부 관리
  const [selectedPeriod, setSelectedPeriod] = useState("전체");
  const selectedPeriodRef = useRef(selectedPeriod);
  const navigate = useNavigate();
  const code = localStorage.getItem("code");
  const periods = ["전체", "1일", "1주", "1개월", "1년"];
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 관리
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [searchResults, setSearchResults] = useState([]);
  const _id = localStorage.getItem("_id");
  const [totalPages, setTotalPages] = useState(1);
  const pageGroupSize = 10; // 한번에 보여줄 페이지 버튼 수
  const currentGroup = Math.floor((currentPage - 1) / pageGroupSize); // 현재 몇 번째 페이지 그룹인지
  const startPage = currentGroup * pageGroupSize + 1;
  const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handlePeriodSelect = (period) => {
    setSelectedPeriod(period);
  };

  const resetOptions = () => {
    setSelectedPeriod("전체");
    selectedPeriodRef.current = '전체';
    fetchData(); // 초기화 후 데이터를 다시 가져옴
  };

  const handleSearch = () => {
    fetchData(); // 검색 옵션에 따라 데이터를 가져옴
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.post("/api/download", {
        params: {
          period: selectedPeriodRef.current,
          _id : _id,
          page: currentPage,
          page_size: resultsPerPage 
        },
      });

      if (response && response.status === 200) {
        const { results, total_count } = response.data;
        setSearchResults(results);

        const calculatedPages = Math.ceil(total_count / resultsPerPage);
        setTotalPages(calculatedPages);
      }
    } catch (error) {
      console.error("데이터를 가져오는 중 오류 발생:", error);
    }
  },[resultsPerPage, currentPage, _id]);

  useEffect(() => {
    if (code === '2') {
      alert("권한이 없습니다.");
      navigate("/dashboard/home");
    }
    fetchData(); // 컴포넌트가 마운트될 때 fetchData 호출
  }, [fetchData, code, navigate]);

  useEffect(() => {
    selectedPeriodRef.current = selectedPeriod;
  }, [selectedPeriod]);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDownload = (fileUrl) => {
    const link = document.createElement("a"); // 다운로드를 위한 링크 생성
    link.href = fileUrl;
    link.download = ""; // 파일 이름을 지정하려면 추가 (예: "filename.mp3")
    document.body.appendChild(link); // 링크를 DOM에 추가
    link.click(); // 다운로드 트리거
    document.body.removeChild(link); // 링크 제거
  };

  return (
    <div className="download">
      <div className="search-options">
        <p className="option-title">검색 옵션 설정</p>
        <button className="toggle-button" onClick={toggleOptions}>
          <IoIosOptions size={20} style={{ marginRight: "8px" }} />
          옵션
        </button>
      </div>
      {showOptions && (
        <div className="options-container">
          <div className="option-group">
            <h3>기간</h3>
            <div className="option-buttons">
              {periods.map((period) => (
                <button
                  key={period}
                  className={`option-button ${
                    selectedPeriod === period ? "selected" : ""
                  }`}
                  onClick={() => handlePeriodSelect(period)}
                >
                  {period}
                </button>
              ))}
            </div>
          </div>
          <div className="options-footer">
            <button className="seach-button" onClick={handleSearch}>
              검색
            </button>
            <button className="reset-button" onClick={resetOptions}>
              초기화
            </button>
          </div>
        </div>
      )}
      <div className="search-result">
        <div className="checkbutton">
          <div className="left-group">
            <label>
              페이지당 보기:
              <select
                value={resultsPerPage} 
                onChange={(e) => {
                  setResultsPerPage(Number(e.target.value));
                  setCurrentPage(1);
                }}
                style={{ marginLeft: '10px', padding: '5px' }}
              >
                <option value={10}>10개씩</option>
                <option value={20}>20개씩</option>
                <option value={40}>40개씩</option>
                <option value={100}>100개씩</option>
              </select>
            </label>
          </div>
        </div>
        <table className="result-table">
          <thead>
            <tr>
              <th>No</th>
              <th>파일명</th>
              <th>날짜</th>
              <th>팀명</th>
              <th>다운로드</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((result, index) => (
              <tr key={result.file_id}>
                <td>{(currentPage - 1) * resultsPerPage + index + 1}</td>
                <td>{result.filename}</td>
                <td>{result.upload_date}</td>
                <td>{result.team}</td>
                <td>
                  <button
                    className="download-button"
                    onClick={() => handleDownload(result.file_url)}
                  >
                    <IoMdDownload size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          {/* 이전 그룹으로 이동 */}
          {startPage > 1 && (
            <button
              className="page-button"
              onClick={() => handlePageChange(startPage - 1)}
            >
              이전
            </button>
          )}
          {/* 현재 그룹의 페이지 버튼 */}
          {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
            <button
              key={startPage + i}
              className={`page-button ${
                currentPage === startPage + i ? "active" : ""
              }`}
              onClick={() => handlePageChange(startPage + i)}
            >
              {startPage + i}
            </button>
          ))}

          {/* 다음 그룹으로 이동 */}
          {endPage < totalPages && (
            <button
              className="page-button"
              onClick={() => handlePageChange(endPage + 1)}
            >
              다음
            </button>
          )}
        </div>
      </div>
    </div>
  );
};