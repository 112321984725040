import React, {useState, useEffect, useRef, useCallback } from "react";
import "./style.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import image_background from "../../../static/img/image_background.png"
import { useNavigate } from "react-router-dom";
import npyjs from "npyjs";
import JSZip from "jszip";

export const Labeling = () => {
  const [label, setLabel] = useState('');
  const [fileId, setFileId] = useState('');
  const [fileInfo, setFileInfo] = useState("");
  const [selected, setSelected] = useState(null);
  const [customInput, setCustomInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedSpeciesIndex, setSelectedSpeciesIndex] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [CheckOption, setCheckOption] = useState(false);
  const navigate = useNavigate();
  const userid = localStorage.getItem("userid");
  const _id = localStorage.getItem("_id");
  const code = localStorage.getItem("code");

  //소리 파일 변환
  const [fileKey, setFileKey] = useState("");
  const [presignedUrl, setPresignedUrl] = useState("")
  const previousFileKey = useRef(null);
  const previousPresignedUrl = useRef(null);
  const [urlSpec, setUrlSpec] = useState("");
  const [presignedUrlSpec, setPresignedUrlSpec] = useState("")
  const previousPresignedUrlSpec = useRef(null);

  const audioRef = useRef(null);
  const canvasOscilloscope = useRef(null);
  const canvasSpectrogram = useRef(null);
  const canvasColorbar = useRef(null);
  const canvasPlayback = useRef(null);
  const canvasPlayback2 = useRef(null);
  const animationFrameId = useRef(null);
  const [isLoded, setIsLoded] = useState(false);

  const [audioBuffer, setAudioBuffer] = useState(null);
  const [spectrogramBuffer, setSpectrogramBuffer] = useState(null);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [Frequency, setFrequency] = useState(1);
  const [maxFrequency, setmaxFrequency] = useState(0);
  const [duration, setDuration]= useState(0);
  const [maxVal, setMaxVal] = useState(0);
  const [minVal, setMinval] = useState(0);

  const fetchLabeling = useCallback(async () => {
    try {
      const response = await axios.get("/api/labeling", {
        params: { currentIndex, _id, checkoption: CheckOption }
      });
      if (response.status === 200) {
        setLabel(response.data.file_name);
        setFileId(response.data.file_id);
        setFileInfo(response.data.file_info);
        const newFileKey = `compression/${response.data.file_name}.aac`;
        if (newFileKey !== previousFileKey.current) {
          previousFileKey.current = newFileKey;
          setFileKey(newFileKey);
          setUrlSpec(response.data.url_spec);
        }
      }
    } catch (error) {
      setLabel("");
      setFileInfo("");
      console.error("라벨 데이터를 가져오는 중 오류 발생:", error);
    }
  }, [currentIndex, _id, CheckOption]); 

  useEffect(() => {
    if (code === '1') {
      alert("권한이 없습니다.");
      navigate("/dashboard/home");
      return;
    }

    fetchLabeling();

  }, [code, navigate, fetchLabeling]);

  useEffect(() => {
    if (!fileKey && !urlSpec) return;

    const fetchPresignedUrls = async () => {
      try {
        const [res1, res2] = await Promise.all([
          axios.get(`/api/proxy/${fileKey}`),
          axios.get(`/api/proxy/${urlSpec}`)
        ]);
        if (res1.data.url !== previousPresignedUrl.current) {
          previousPresignedUrl.current = res1.data.url;
          setPresignedUrl(res1.data.url);
        }
        if (res2.data.url !== previousPresignedUrlSpec.current) {
          previousPresignedUrlSpec.current = res2.data.url;
          setPresignedUrlSpec(res2.data.url);
        }
      } catch (error) {
        console.error("Failed to fetch presigned URLs:", error);
      }
    };
  
    fetchPresignedUrls();
  }, [fileKey, urlSpec]);

  useEffect(() => {
    if (!presignedUrl || !presignedUrlSpec) return;
    const audioContext = new AudioContext();
    let audioUrl;

    const processAudioAndSpectrogram = async () => {
      try {
        // 오디오 데이터 처리
        const audioResponse = await fetch(presignedUrl);
        const audioData = await audioResponse.arrayBuffer();

        const blob = new Blob([audioData], { type: "audio/wav" });
        audioUrl = URL.createObjectURL(blob);
        if (audioRef.current) {
          audioRef.current.src = audioUrl;
          audioRef.current.onloadedmetadata = () => {
            setDuration(Math.round(audioRef.current.duration));
          };
        }
        const decodedBuffer = await audioContext.decodeAudioData(audioData);
        setAudioBuffer(decodedBuffer);
        adjustCanvasSize(canvasOscilloscope.current, canvasSpectrogram.current, canvasPlayback.current, canvasPlayback2.current);

        // 스펙트로그램 데이터 처리
        const specResponse = await fetch(presignedUrlSpec);
        const npzData = await specResponse.arrayBuffer();

        const zip = await JSZip.loadAsync(npzData);

        const npyFileName = "arr_spec.npy";  // 서버 측 저장 시 사용한 키에 따라 조정하세요.
        const npyData = await zip.file(npyFileName).async("arraybuffer");

        const npy = new npyjs();
        const parsedSpectrogram = npy.parse(npyData).data;

        const minValBuffer = await zip.file("min_val.npy").async("arraybuffer");
        const maxValBuffer = await zip.file("max_val.npy").async("arraybuffer");
        const minValArray = new npyjs().parse(minValBuffer).data;
        const maxValArray = new npyjs().parse(maxValBuffer).data;
        const minVal = minValArray[0];
        const maxVal = maxValArray[0];

        const normalizedSpectrogram = Array.from(parsedSpectrogram, value => value / 255);

        const numFrames = normalizedSpectrogram.length / 2048;
        const spectrogram2D = [];
        for (let i = 0; i < numFrames; i++) {
          spectrogram2D.push(
            Array.from(normalizedSpectrogram.slice(i * 2048, (i + 1) * 2048))
          );
        }
        setSpectrogramBuffer(spectrogram2D);
        setMaxVal(maxVal);
        setMinval(minVal);
      } catch (error) {
        console.error("❌ Failed to process data:", error);
      }
    };

    processAudioAndSpectrogram();

    return () => {
      audioContext.close();
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [presignedUrl, presignedUrlSpec]);

  useEffect(() => {
    const handleResize = () => {
        scaleCanvas(canvasOscilloscope.current);
        scaleCanvas(canvasSpectrogram.current);
        scaleCanvas(canvasPlayback.current);
        scaleCanvas(canvasPlayback2.current);
      };

    window.addEventListener("resize", handleResize);

    return () => {
        window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scaleCanvas = (canvas) => {
    const parent = canvas.parentElement;
    if (!parent) {
        console.error("Parent element not found.");
        return;
    }

    const { width, height } = parent.getBoundingClientRect();

    // CSS 스타일로 크기 조정
    canvas.style.width = `${Math.floor(width)}px`;
    canvas.style.height = `${Math.floor(height)}px`;
  } ;

  const adjustCanvasSize = (canvas1, canvas2, canvas3, canvas4) => {
    const parent = canvas1.parentElement;
    const parent2 = canvas2.parentElement;
    const parent3 = canvas3.parentElement;
    const parent4 = canvas4.parentElement;
    if (!parent && !parent2 && !parent3 && !parent4) {
      console.error("Parent element not found.");
      return;
    }
  
    const { width: width1, height: height1 } = parent.getBoundingClientRect();
    const { width: width2, height: height2 } = parent2.getBoundingClientRect();
    const { width: width3, height: height3 } = parent3.getBoundingClientRect();
    const { width: width4, height: height4 } = parent4.getBoundingClientRect();
    if (width1 === 0 || height1 === 0 || width2 === 0 || height2 === 0 || width3 === 0 || height3 === 0 || width4 === 0 || height4 === 0) {
      console.error("Parent dimensions are invalid:", width1, height1, width2, height2, width3, height3, width4, height4);
      return;
    }
    const dpr = window.devicePixelRatio || 1;
  
    canvas1.style.width = `${Math.floor(width1)}px`;
    canvas1.style.height = `${Math.floor(height1)}px`;
    canvas2.style.width = `${Math.floor(width2)}px`;
    canvas2.style.height = `${Math.floor(height2)}px`;
    canvas3.style.width = `${Math.floor(width2)}px`;
    canvas3.style.height = `${Math.floor(height2)}px`;
    canvas4.style.width = `${Math.floor(width1)}px`;
    canvas4.style.height = `${Math.floor(height1)}px`;

    canvas1.width = Math.floor(width1 * dpr);
    canvas1.height = Math.floor(height1 * dpr);
    canvas2.width = Math.floor(width2 * dpr * 2);
    canvas2.height = Math.floor(height2 * dpr * 2);
    canvas3.width = Math.floor(width2 * dpr * 2);
    canvas3.height = Math.floor(height2 * dpr * 2);
    canvas4.width = Math.floor(width1 * dpr);
    canvas4.height = Math.floor(height1 * dpr);
  };

  const drawWaveform = (audioBuffer, scale = 1) => {
    const canvas = canvasOscilloscope.current;
    const ctx = canvas.getContext("2d");
    const data = audioBuffer.getChannelData(0); // 왼쪽 채널 데이터 가져오기

    const { width, height } = canvas;

    const gridColor = "#ccc"; // 격자선 색상
  
    ctx.clearRect(0, 0, width, height);

    ctx.strokeStyle = gridColor;
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(0, height / 2);
    ctx.lineTo(width, height / 2);
    ctx.stroke();

    // 격자선 그리기 (x축)
    const numxLines = 10;
    ctx.strokeStyle = gridColor;
    ctx.lineWidth = 1;


    for (let i = 1; i < numxLines; i++) {
      const xPosition = (width / numxLines) * i;
      ctx.beginPath();
      ctx.moveTo(xPosition, 0);
      ctx.lineTo(xPosition, height);
      ctx.stroke();
    }


    ctx.strokeStyle = "#5A6268"; // 파형 색상
    ctx.lineWidth = 1;
    ctx.beginPath();

    const step = Math.ceil(data.length / width);
    const amp = height / 2;
  
    for (let i = 0; i < width; i++) {
      const min = Math.min(...data.slice(i * step, (i + 1) * step)) * scale;
      const max = Math.max(...data.slice(i * step, (i + 1) * step)) * scale;
      ctx.moveTo(i, (1 + min) * amp);
      ctx.lineTo(i, (1 + max) * amp);
    }
    ctx.stroke();
  };
  
  const drawSpectrogram = (spectrogram, scale = 1, minV, maxV) => {
    if (!spectrogram || !Array.isArray(spectrogram) || spectrogram.length === 0) {
      console.error("❌ 스펙트로그램 데이터가 없습니다.");
      return;
    }
    
    const canvas = canvasSpectrogram.current;
    if (!canvas) { 
      console.error("❌ Canvas 요소를 찾을 수 없습니다.");
      return;
    }

    const ctx = canvas.getContext("2d");
    const dpr = window.devicePixelRatio || 1;
    const { width, height } = canvas;
  
    // dpr 적용된 실제 렌더링 크기
    const scaledWidth = width / (dpr* 2);
    const scaledHeight = height / (dpr* 2);
  
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.scale(dpr*2, dpr*2); // 스케일 설정
    ctx.clearRect(0, 0, width, height); // 기존 내용 제거

    
    const numFrames = spectrogram.length; // 프레임 개수 (x축)
    const fftSize = 2048
    const nyquistFrequency = 24000;

    setmaxFrequency(nyquistFrequency);

    const minFrequency = 0;
    const maxFrequency = nyquistFrequency / scale; 
    const minIndex = Math.floor((minFrequency / nyquistFrequency) * (fftSize / 2));
    const maxIndex = Math.ceil((maxFrequency / nyquistFrequency) * (fftSize / 2));
  
    // dpr을 반영하여 xScale, yScale 계산
    const xScale = scaledWidth / numFrames; // 실제 스케일 기준
    const yScale = scaledHeight / (maxIndex - minIndex);

    // 플라즈마 색상 매핑 함수
    const getEnergyColor = (intensity) => {
      // 0~1 범위로 강도 값을 클램핑
      const normalized = Math.max(0, Math.min(1, intensity));
    
      // Inferno 컬러맵의 색상 구간 (각 구간: [위치, R, G, B])
      // 이 값들은 대략적인 inferno 색상값입니다.
      const colorStops = [
        [0.0, 0, 0, 4],         // 0.0: 아주 어두운 파랑/검정 (#000004)
        [0.25, 87, 15, 109],     // 0.25: 어두운 보라 (#570f6d)
        [0.5, 187, 55, 84],      // 0.5: 붉은 계열 (#bb3754)
        [0.75, 249, 142, 8],     // 0.75: 주황 (#f98e08)
        [1.0, 252, 254, 164]     // 1.0: 옅은 노랑 (#fcfea4)
      ];
    
      // 정규화된 강도 값이 어느 구간에 속하는지 확인하고 선형 보간을 수행
      for (let i = 0; i < colorStops.length - 1; i++) {
        const [t1, r1, g1, b1] = colorStops[i];
        const [t2, r2, g2, b2] = colorStops[i + 1];
        if (normalized >= t1 && normalized <= t2) {
          const ratio = (normalized - t1) / (t2 - t1);
          const r = Math.round(r1 + (r2 - r1) * ratio);
          const g = Math.round(g1 + (g2 - g1) * ratio);
          const b = Math.round(b1 + (b2 - b1) * ratio);
          return `rgb(${r}, ${g}, ${b})`;
        }
      }
      // 만약 정상 범위에 있지 않으면, 마지막 구간의 색상을 반환
      const lastStop = colorStops[colorStops.length - 1];
      return `rgb(${lastStop[1]}, ${lastStop[2]}, ${lastStop[3]})`;
    };

    for (let x = 0; x < numFrames; x++) {
      for (let y = 0; y < fftSize / 2; y++) {
        const intensity = spectrogram[x][y];
        const color = getEnergyColor(intensity);
        ctx.fillStyle = color;
        // dpr을 고려한 좌표 설정
        ctx.fillRect(
          Math.round(x * xScale),
          Math.round(scaledHeight - y * yScale),
          Math.ceil(xScale), // 간격 없이 연결
          Math.ceil(yScale)
        );
      }
    }
    // 격자선 그리기 (y축)
    const numGridLines = 5; // 총 5개 선 (4개 간격)
    const gridColor = "rgba(200, 200, 200, 0.3)"; // 흰색 반투명
    ctx.strokeStyle = gridColor;
    ctx.lineWidth = 1;

    
    for (let i = 1; i < numGridLines; i++) {
      const yPosition = (scaledHeight / numGridLines) * i;
      ctx.beginPath();
      ctx.moveTo(0, yPosition);
      ctx.lineTo(scaledWidth, yPosition);
      ctx.stroke();
    }

    // 격자선 그리기 (x축)
    const numxLines = 10;
    ctx.strokeStyle = gridColor;
    ctx.lineWidth = 1;

    
    for (let i = 1; i < numxLines; i++) {
      const xPosition = (scaledWidth / numxLines) * i;
      ctx.beginPath();
      ctx.moveTo(xPosition, 0);
      ctx.lineTo(xPosition, scaledHeight);
      ctx.stroke();
    }

    ctx.save();
    ctx.restore();


    const colorbarcanvas = canvasColorbar.current;
    if (!colorbarcanvas) { 
      console.error("❌ Canvas 요소를 찾을 수 없습니다.");
      return;
    }

    colorbarcanvas.width = colorbarcanvas.offsetWidth;
    colorbarcanvas.height = colorbarcanvas.offsetHeight;

    // dB값 계산
    if (maxV > 1) {
      maxV = 1
    }

    const epsilon = 1e-12;
    const dBmin = 20 * Math.log10((minV + epsilon));
    const dBmax = 20 * Math.log10((maxV + epsilon));

    // 컬러바
    const ctxColorBar = colorbarcanvas.getContext("2d");

    function drawColorBar(ctx, canvasWidth, canvasHeight, getEnergyColor, minTick, maxTick) {
      

      // 1) 컬러바 폭과 위치 설정
      const marginTop = 10;
      const marginBottom = 10;

      const barX = 0;
      const barY = marginTop;
      const barWidth = 15;
      const barHeight = canvasHeight - (marginTop + marginBottom);
    
      // 2) 컬러바 그리기 (상단 = 강도1, 하단 = 강도0)
      for (let i = 0; i < barHeight; i++) {
        const normalized = 1 - i / barHeight;
        ctx.fillStyle = getEnergyColor(normalized);
        ctx.fillRect(barX, barY + i, barWidth, 1);
      }
    
      // 컬러바 외곽선
      ctx.strokeStyle = "black";
      ctx.lineWidth = 1;
      ctx.strokeRect(barX, barY, barWidth, barHeight);
    
      // 3) 눈금 그리기
      // 예: 0~100 범위, 6개 눈금 (0,20,40,60,80,100)
      const numTicks = 6;
      const step = (maxTick - minTick) / (numTicks - 1);
      for (let i = 0; i < numTicks; i++) {
        const value = minTick + step * i;
        // ratio: 0 => 하단, 1 => 상단
        const ratio = (value - minTick) / (maxTick - minTick);
        // 컬러바가 상단=강도1 이므로, y좌표는 (1 - ratio) * barHeight
        const yPos = barY + (1 - ratio) * barHeight;
    
        // 짧은 눈금선: barWidth ~ barWidth+5
        ctx.beginPath();
        ctx.moveTo(barX + barWidth, yPos);
        ctx.lineTo(barX + barWidth + 5, yPos);
        ctx.stroke();
    
        // 텍스트 (바깥쪽 = x=barWidth+8, y=yPos)
        ctx.font = "10px AppleSDGothicNeoB";
        ctx.fillStyle = "black";
        ctx.textBaseline = "middle";
        // yPos가 글자 높이를 고려해 약간 아래로 보이도록 +4
        ctx.fillText(Math.round(value).toString() + 'dB', barX + barWidth + 8, yPos);
      }
    
    }

    drawColorBar(ctxColorBar, colorbarcanvas.width, colorbarcanvas.height, getEnergyColor, dBmin, dBmax);

    setIsLoded(true);

  };


  const drawPlaybackLine = useCallback(() => {
    if (!canvasPlayback.current || !audioRef.current) return;
  
    const canvas = canvasPlayback.current;
    const ctx = canvas.getContext("2d");
    const { width, height } = canvas;
  
    const audio = audioRef.current;
    const duration = audio.duration;
    const currentTime = audio.currentTime;
  
    if (!duration || isNaN(currentTime)) return;
  
    ctx.clearRect(0, 0, width, height); 
  
    ctx.strokeStyle = "red";
    ctx.lineWidth = 3;
    ctx.beginPath();
    const xPosition = (currentTime / duration) * width;
    ctx.moveTo(xPosition, 0);
    ctx.lineTo(xPosition, height);
    ctx.stroke();

    // animationFrameId.current = requestAnimationFrame(drawPlaybackLine);
  
  }, []);

  const drawPlaybackLine2 = useCallback(() => {
    if (!canvasPlayback2.current || !audioRef.current) return;
  
    const canvas = canvasPlayback2.current;
    const ctx = canvas.getContext("2d");
    const { width, height } = canvas;
  
    const audio = audioRef.current;
    const duration = audio.duration;
    const currentTime = audio.currentTime;
  
    if (!duration || isNaN(currentTime)) return;
  
    ctx.clearRect(0, 0, width, height); 
  
    ctx.strokeStyle = "red";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    const xPosition = (currentTime / duration) * width;
    ctx.moveTo(xPosition, 0);
    ctx.lineTo(xPosition, height);
    ctx.stroke();

    // animationFrameId.current = requestAnimationFrame(drawPlaybackLine2);
  
  }, []);

  const drawPlaybackLines = useCallback(() => {
    // 두 캔버스가 모두 존재할 때 각각 그리기 함수를 호출합니다.
    if (audioRef.current && canvasPlayback.current) {
      drawPlaybackLine();  // 첫 번째 캔버스에 그리기
    }
    if (audioRef.current && canvasPlayback2.current) {
      drawPlaybackLine2(); // 두 번째 캔버스에 그리기
    }
    // 다음 프레임 예약: 두 함수 모두 호출한 후 wrapper 함수를 재귀적으로 호출합니다.
    animationFrameId.current = requestAnimationFrame(drawPlaybackLines);
  }, [drawPlaybackLine, drawPlaybackLine2]);

  useEffect(() => {
    const startDrawing = () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      animationFrameId.current = requestAnimationFrame(drawPlaybackLines);
    };
  
    const stopDrawing = () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
        animationFrameId.current = null;
      }
    };
  
    const checkAndRegister = () => {
      const audio = audioRef.current;
      // 두 캔버스 중 적어도 하나라도 존재하는지 확인 (혹은 둘 다 있어야 한다면 && 로 변경)
      const canvas1 = canvasPlayback.current;
      const canvas2 = canvasPlayback2.current;
      if (audio && (canvas1 || canvas2) && audio.readyState >= 2) {
        audio.addEventListener("play", startDrawing);
        audio.addEventListener("pause", stopDrawing);
        audio.addEventListener("ended", stopDrawing);
        return true;
      }
      return false;
    };
  
    const intervalId = setInterval(() => {
      if (checkAndRegister()) {
        clearInterval(intervalId);
      }
    }, 100);
  
    // cleanup: 리스너 제거 및 애니메이션 취소
    const currentAudio = audioRef.current;
    return () => {
      clearInterval(intervalId);
      if (currentAudio) {
        currentAudio.removeEventListener("play", startDrawing);
        currentAudio.removeEventListener("pause", stopDrawing);
        currentAudio.removeEventListener("ended", stopDrawing);
      }
      stopDrawing();
    };
  }, [drawPlaybackLines]);

  useEffect(() => {
    if (audioBuffer) {
      drawWaveform(audioBuffer, scaleFactor);
    }
  }, [audioBuffer ,scaleFactor]);

  useEffect(() => {
    setIsLoded(false);
    if (spectrogramBuffer) {
      drawSpectrogram(spectrogramBuffer, Frequency, minVal, maxVal);
    }
  }, [spectrogramBuffer, Frequency, minVal, maxVal]);

  const handlePrevClick = () => {
    if (currentIndex !== 0) {
      setCurrentIndex((currentIndex) => Math.max(currentIndex - 1, 0));
      setSelected(null);
      setSelectedSpeciesIndex(null);
      setIsLoded(false);
      if (audioRef.current?.src) {
        URL.revokeObjectURL(audioRef.current.src); // 기존 Blob URL 해제
        audioRef.current.src = ""; // 오디오 src 초기화
      }
    }
  };

  const handleNextClick = async () => {  
    try {
      const response = await axios.get("/api/labeling", {
        params: { currentIndex: currentIndex + 1, _id, checkoption: CheckOption }
      });
  
      if (response.status === 200) {
        setCurrentIndex((prev) => prev + 1);
        setSelected(null);
        setSelectedSpeciesIndex(null);
        setIsLoded(false);
        if (audioRef.current?.src) {
          URL.revokeObjectURL(audioRef.current.src);
          audioRef.current.src = "";
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return;
      } else {
        console.error("❌ 라벨 데이터를 가져오는 중 오류 발생:", error);
      }
      
    }
  };

  const handleSubmitClick = async () => {
    let selectedKey = keys[selected];
    let selectedValue;

    if (selectedKey === "기타") {
      selectedValue = customInput;
      Object.keys(groups).forEach((groupName) => {
        groups[groupName].forEach((item) => {
          if (item === selectedValue) {
            selectedKey = groupName;
          }
        });
      });
    } else if (selectedKey === "알수없음") {
      selectedValue = selectedKey
    } else {
      selectedValue = groups[selectedKey][selectedSpeciesIndex];
    }
    
    try {
      const response = await axios.post('/api/labeling_submit', {
        file_id: fileId,
        user_id: userid,
        class_1: selectedKey, 
        class_2: selectedValue,
      }, {
        headers: {
          'Content-Type': 'application/json', // JSON 형식으로 전송
        },
        withCredentials: true, // 세션 사용을 위해 쿠키 허용
      });
    
      if (response.status === 200) {

        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
        setSelected(null);
        setSelectedSpeciesIndex(null);
        setIsLoded(false);
        if (audioRef.current?.src) {
          URL.revokeObjectURL(audioRef.current.src);
          audioRef.current.src = "";
        }
        fetchLabeling();
      } else {
        console.log('라벨링 업로드 실패');
      }
    } catch (error) {
      console.error('파일 업로드 중 오류 발생:', error);
    }
  };

  const groups = {
    매미류: ["털매미", "늦털매미", "말매미", "참깽깽매미", "유지매미", "참매미", "애매미", "쓰름매미", "소요산매미", "알수없음"],
    조류: [
      "큰기러기", "참새", "집비둘기", "까치", "붉은머리오목눈이", "큰고니", "멧비둘기", "직박구리", "박새", "쑥새", "되새",
      "방울새", "노랑턱멧새", "알락할미새", "딱새", "큰부리까마귀", "물까치", "오목눈이", "떼까마귀", "종다리", "파랑새", "새호리기", "알수없음"
    ],
    무미양서류: [
      "청개구리", "수원청개구리", "두꺼비", "맹꽁이", "황소개구리", "무당개구리", "큰산개구리", "참개구리", "옴개구리",
      "한국산개구리", "금개구리", "계곡산개구리", "물두꺼비", "알수없음"
    ],
    메뚜기목: [
      "여치 긴날개여치", "갈색여치", "애여치", "베짱이", "검은다리실베짱이", "긴날개중베짱이", "중베짱이", "실베짱이", "줄베짱이",
      "쌕쌔기", "긴꼬리쌕쌔기", "등줄어리쌕쌔기", "매부리", "왕귀뚜라미", "모대가리귀뚜라미", "알락귀뚜라미", "청솔귀뚜라미", 
      "극동뀌뚜라미", "방울벌레", "좀방울벌레", "알락방울벌레", "삽사리", "풀종다리", "알수없음"
    ],
    포유류: ["고라니", "고양이", "다람쥐", "멧돼지", "관박쥐", "집박쥐", "안주애기박쥐", "대륙큰수염박쥐", "문둥이박쥐", "알수없음"],
    기타: [],
    알수없음 : [],
  };

  const keys = Object.keys(groups);

  const handleCustomInputChange = (e) => {
    const value = e.target.value;
    setCustomInput(value);
  
    // 입력이 없으면 결과 초기화
    if (!value.trim()) {
      setFilteredResults([]);
      return;
    }
  
    // Set을 사용해 중복 제거
    const resultsSet = new Set();
    Object.keys(groups).forEach((groupName) => {
      groups[groupName].forEach((item) => {
        if (item.includes(value)) {
          resultsSet.add(item);
        }
      });
    });
  
    // Set을 배열로 변환하여 상태 업데이트
    setFilteredResults([...resultsSet]);
  };

  const handleResultClick = (result) => {
    setCustomInput(result);
    setFilteredResults([]);
  };

  const handleSelect = (index) => {
    setSelected(index);
    setSelectedSpeciesIndex(null);
    setCustomInput("");
  };

  const handleSpeciesSelect = (index) => {
    setSelectedSpeciesIndex(index);
  };

  const handleCheckboxChange = (e) => {
    setCheckOption(e.target.checked); // 체크박스 상태 업데이트
  };

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);
    
    // 분과 초를 2자리 문자열로 변환 (10 미만이면 앞에 '0' 추가)
    const minuteStr = minutes < 10 ? '0' + minutes : String(minutes);
    const secondStr = seconds < 10 ? '0' + seconds : String(seconds);
    
    return `${minuteStr}:${secondStr}`;
  };

  return (
    <>
      <div className="title-space">
        <div className="text-area">
          <h1>소리 분석</h1>
        </div>
        <div className="image-area">
          <img src={image_background} alt="background decoration" className="side-image"/>
        </div>
      </div>
      <div className="labeling-main">
        <div className="labeling">
          <div className="checkbox-container">
            <label>
              <input
                type="checkbox"
                className="checkbox"
                checked={CheckOption}
                onChange={handleCheckboxChange} // 체크 여부를 상태로 관리
              />
              내가 올린 파일만 라벨링 진행하기
            </label>
          </div>
          <div className="label-box">
            <button className="arrow-button" onClick={handlePrevClick}>
              <IoIosArrowBack size={40}/>
            </button>
            <div className="label">
              {label}
            </div>
            <button className="arrow-button" onClick={handleNextClick}>
              <IoIosArrowForward size={40}/>
            </button>
          </div>
          <div className="main-box">
            <div className="scale-box">
              <button className="scale-button" onClick={() => setScaleFactor(1)}>1x</button>
              <button className="scale-button" onClick={() => setScaleFactor(2)}>2x</button>
              <button className="scale-button" onClick={() => setScaleFactor(4)}>4x</button>
            </div>
            <div className="y-grid-box">
              <p className='w-p1'>{1/scaleFactor}</p>
              <p className='w-p2'>0</p>
              <p className='w-p3'>{-1/scaleFactor}</p>
            </div>
            <div className="sub-box">
              <div className="wave-box">
                <canvas ref={canvasOscilloscope} className="oscilloscope"></canvas>
                <canvas ref={canvasPlayback2} className="playback-line2" style= {{ position : "absolute", maxWidth : "100%" }}></canvas>
              </div>
              <div className="x-grid-box">
                <p className='p11'>{formatTime(Math.round(duration/10*2))}</p>
                <p className='p22'>{formatTime(Math.round(duration/10*4))}</p>
                <p className='p33'>{formatTime(Math.round(duration/10*6))}</p>
                <p className='p44'>{formatTime(Math.round(duration/10*8))}</p>
              </div>
            </div>
            <div className="colormap-box">
            </div>
          </div>
          <div className="main-box"> 
            <div className="scale-box">
              <button className="scale-button2" onClick={() => setFrequency(1)}>{maxFrequency/1000}K</button>
              <button className="scale-button2" onClick={() => setFrequency(3)}>{maxFrequency/1000/3}K</button>
              <button className="scale-button2" onClick={() => setFrequency(6)}>{maxFrequency/1000/6}K</button>
            </div>
            <div className="y-grid-box">
              <p className='p1'>{maxFrequency/5*5/Frequency}</p>
              <p className='p2'>{maxFrequency/5*4/Frequency}</p>
              <p className='p3'>{maxFrequency/5*3/Frequency}</p>
              <p className='p4'>{maxFrequency/5*2/Frequency}</p>
              <p className='p5'>{maxFrequency/5*1/Frequency}</p>
              <p className='p6'>0</p>
            </div>
            <div className='sub-box'>
              <div className="spectrogram-box">
                <canvas ref={canvasSpectrogram} className="spectrogram"></canvas>
                <canvas ref={canvasPlayback} className="playback-line" style= {{ position : "absolute", maxWidth : "100%" }}></canvas>
                {!isLoded && (
                  <div
                    className="spinner-overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255,255,255,0.8)",
                      zIndex: 10,
                    }}
                  > 
                    <div className="spinner" />
                  </div>
                )}
              </div>
              <div className="x-grid-box">
                <p className='p11'>{formatTime(Math.round(duration/10*2))}</p>
                <p className='p22'>{formatTime(Math.round(duration/10*4))}</p>
                <p className='p33'>{formatTime(Math.round(duration/10*6))}</p>
                <p className='p44'>{formatTime(Math.round(duration/10*8))}</p>
              </div>
            </div>
            <div className="colormap-box">
              <canvas ref={canvasColorbar} className="colorbar"></canvas>
            </div>
          </div>
          <div className="audio-player" onContextMenu={(e) => e.preventDefault()}>
            {presignedUrl ? (
              <audio 
                style={{width:'100%', marginBottom: '10px'}} 
                ref={audioRef} 
                controls 
                crossOrigin="anonymous" 
                controlsList="nodownload"
              ></audio>
            ) : (
              <p>음성 파일 로딩중입니다.</p>
            )}
          </div>
          { fileInfo && (
            <div className="label-info">*해당 소리에 대한 업로더의 메모 : {fileInfo}</div>
          )}
          <div className="question-box">
            <div className="button-title">
              Q1: 무슨 분류군일까요?
            </div>
            <div className="button-group-wrapper">
              <div className="button-group">
                {["매미류", "조류", "무미양서류", "메뚜기류", "포유류", "기타", "알수없음"].map((label, index) => (
                  <button
                    key={index}
                    className={`button-item ${selected === index ? "selected" : ""}`}
                    onClick={() => handleSelect(index)}
                  >
                    {index + 1}) {label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="question-box">
            <div className="button-title">
              Q2: 무슨 종일까요?
            </div>
            {selected !== null && (
              <div className="species-group-wrapper">
                {selected !== 5 ? (
                  // 선택된 분류군에 따라 종 목록 표시
                  groups[Object.keys(groups)[selected]]?.map((species, index) => (
                    <div
                      key={index}
                      className={`species-item ${selectedSpeciesIndex === index ? "selected" : ""}`}
                      onClick={() => handleSpeciesSelect(index)}
                    >
                      {species}
                    </div>
                  ))
                ) : (
                  // "기타" 선택 시 사용자가 직접 입력할 수 있는 필드 표시
                  <div>
                    <input
                      type="text"
                      value={customInput}
                      placeholder="직접 입력해주세요."
                      onChange={handleCustomInputChange}
                      className="custom-input"
                    />
                    {filteredResults.length > 0 && (
                      <div className="search-results">
                        {filteredResults.map((result, index) => (
                          <div key={index} onClick={() => handleResultClick(result)} className="search-result-item">
                            {result}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                )}
              </div>
            )}
          </div>
          <div className="button-box">
            <button className="submit-button" onClick={handleSubmitClick}>
              완료
            </button>
          </div>
        </div>
      </div>
    </>
  );
};