import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const expiry = localStorage.getItem("expiry");

  if (!isLoggedIn || !expiry || Date.now() > Number(expiry)) {
    // 로그인하지 않은 상태라면 로그인 페이지로 리디렉션
    localStorage.clear();
    return <Navigate to="/" replace />;
  }

  // 로그인한 상태라면 자식 컴포넌트를 렌더링
  return children;
};

export default ProtectedRoute;